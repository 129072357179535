<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-location-pin" />
        <h5 class="d-inline ml-2">Delivery Zones</h5>
      </CCardHeader>
      <CCardBody>

        <!-- Filters -->
        <CCard class="filters" accent-color="warning">
          <CCardHeader
            role="button"
            class="text-warning shadow-none card-header"
            @click="resetFilters"
          >
            <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
            <div class="card-header-actions">
              <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
            </div>
          </CCardHeader>

          <CCollapse :show="$store.state.filterShow">
            <CCardBody class="p-2">
              <div class="row">
                <div class="col-12 mb-2">
                  <CInputCheckbox
                    label="Show regions with only active restaurants"
                    custom
                    :checked.sync="showRegionsWithOnlyActiveRestaurants"
                    @update:checked="getAllZips"
                  />
                </div>
                <!-- Country Filter -->
                <div class="col-md-4 mb-2 mb-lg-0">
                  <v-select
                    class="v-select-filter"
                    placeholder="Country.."
                    :value="$store.state.filters.country"
                    :options="authCountries"
                    :reduce="c => c.value"
                    :searchable="false"
                    @input="countryFilter"
                  />
                </div>

                <!-- Region -->
                <div class="col-md mb-2 mb-lg-0">
                  <v-select
                    class="v-select-filter"
                    placeholder="Please select regions.."
                    multiple
                    v-model="selectedZips"
                    :options="allZips"
                    :reduce="zc => ({id: zc.id, zcZip: zc.zcZip, zcCity: zc.zcCity, zcCountryId: zc.zcCountryId})"
                    :filter="filteredZips"
                    :loading="loading"
                  >
                    <template v-slot:option="zip_code">
                      <strong>{{ zip_code.zcZip }}</strong> -
                      <span>{{ zip_code.zcCity }} </span>
                    </template>
                    <span slot="no-options">Sorry, no matching options.</span>
                  </v-select>
                </div>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>

      </CCardBody>
    </CCard>

    <CRow>
      <CCol lg="12">
        <zone-table :country="country" :zips="selectedZips" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ZoneTable from "./Table";

export default {
  name: "AllRestaurantsOnMap",
  components: {
    ZoneTable,
  },
  data() {
    return {
      allZips: [],
      loading: false,
      showRegionsWithOnlyActiveRestaurants: true
    }
  },

  computed: {
    ...mapState(['filters']),

    country() {
      return this.filters.country;
    },
    selectedZips: {
      get () { return this.filters.delivery_zones.selectedZips.filter(z => z.zcCountryId == this.country) },
      set (value) { this.filters.delivery_zones.selectedZips = value },
    },
  },

  async mounted() {
    await this.getAllZips();
  },

  methods: {
    async getAllZips() {
      if (!this.country) {
        this.allZips = [];
        return;
      }

      this.loading = true;
      await this.$axios
        .get(this.$backend.ZIP_CODES.GET_ALL, {
          params: {
            country: this.country,
            fields: 'zcZip,zcCity',
            nobounds: true,
            'regions-with-only-active-restaurants': this.showRegionsWithOnlyActiveRestaurants
          },
        })
        .then((response) => {
          this.allZips = response.data.map(el => {
            return {
              ...el,
              label: `${el.zcZip}: ${el.zcCity}`
            }
          })
        })
        .finally(() => { this.loading = false; });
    },


    filteredZips(options, search) {
      return search.length
        ? options.filter(
            (option) =>
              option.zcZip.includes(search) ||
              option.zcCity.toLowerCase().includes(search.toLowerCase())
          )
        : null;
    },

    // Filters
    async countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      await this.getAllZips();
      this.selectedZips = [];
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.allZips = [];
        this.selectedZips = [];
      }
      this.$store.commit('toggle', 'filterShow');
    },
  }
};
</script>
