<template>
  <CRow>

    <CCol sm="12">
      <CCard>
        <CCardHeader class="d-flex flex-column flex-xl-row">
          <div class="flex-grow-1">
            <h5 class="d-inline mr-2">Restaurants in Selected Delivery Zones</h5>
            <h5 v-if="!!items.length" class="d-inline">({{items.length}})</h5>
          </div>

          <div class="">
            <CInputCheckbox
              label="Show only published"
              custom
              :checked.sync="showOnlyPublished"
              @update:checked="getRestaurantsByZips"
            />
          </div>
        </CCardHeader>
      <CCardBody>
        <div class="table-responsive" v-if="!!items.length">
          <CDataTable
            class="zone-table table-outline"
            hover
            :items="items"
            :fields="fields"
            :loading="loading"
            :noItemsView="{
              noResults: 'No filtering results available!',
              noItems: 'No restaurant found!',
            }"
            head-color="light"
            no-sorting
          >
            <td slot="restaurant" slot-scope="{item}">
              <div class="d-flex align-items-center">
                <div class="c-avatar mr-3">
                  <CImg
                    :src="item.restaurant_logo_path"
                    class="c-avatar-img"
                    style="width: 36px; height: 36px"
                    placeholderColor="lightgray"
                  />
                </div>
                <div>
                  <CLink :to="{name: 'View Restaurant', params: { id: item.id }}" class="text-muted">{{ item.restaurant_name }}</CLink>
                  <div>
                    <small><strong><CIcon name="cil-location-pin" size="sm" /> {{item.restaurant_city }}</strong> {{item.restaurant_postcode }}</small>
                  </div>
                </div>
              </div>
            </td>

            <td slot="delivery_zones" slot-scope="{item}">
              <ul class="zone-list">
                <li
                  v-for="[countryId, zones] in Object.entries(item.zips)"
                  :set="zoneCountry = $globalVar.countries.find((c) => c.value == countryId)"
                >
                  <CIcon class="mr-1" :content="$options.flagSet[zoneCountry.flag]" size="lg" />
                  <ul class="zone-list-inline">
                    <li v-for="zone in zones">
                      <small><strong>{{zone.city }}</strong> {{zone.zip }}</small>
                    </li>
                  </ul>
                </li>
              </ul>
            </td>

            <td slot="action" slot-scope="{item}" class="text-center">
              <CButton
                :to="{ name: 'Edit Restaurant', params: { id: item.id }, query: { tab: 5 } }"
                v-c-tooltip="{
                  content: 'Edit Restaurant',
                  placement: 'top',
                }"
                size="sm"
                color="info"
                variant="ghost"
                shape="pill"
              >
                <CIcon name="cil-align-left" />
              </CButton>

              <CButton
                :to="{ name: 'View Restaurant', params: { id: item.id } }"
                v-c-tooltip="{
                  content: 'View Restaurant',
                  placement: 'top',
                }"
                size="sm"
                color="primary"
                variant="ghost"
                shape="pill"
              >
                <CIcon name="cil-restaurant" />
              </CButton>
            </td>
          </CDataTable>
        </div>

        <div v-else>
          <CSpinner v-if="loading" />
          <template v-else>
            <h6 v-if="!zips.length">Select at least one region..</h6>
            <h6 v-else>No restaurant found!</h6>
          </template>
        </div>

      </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { flagSet } from '@coreui/icons'

export default {
  name: "ZoneTable",
  flagSet,
  props: {
    country: { default: null, type: Number },
    zips: { default: null, type: Array },
  },
  data() {
    return {
      items: [],
      showOnlyPublished: false,
      loading: false,
      fields: [
        { key: "restaurant", _style: "min-width: 240px;" },
        { key: "delivery_zones", _style: "min-width: 300px;" },
        { key: "action", _style: "width: 140px; text-align: center;" },
      ],
    };
  },

  watch: {
    zips: {
      deep: true,
      handler: 'getRestaurantsByZips'
    },
  },

  async mounted() {
    await this.getRestaurantsByZips();
  },

  methods: {
    async getRestaurantsByZips() {
      if (!this.zips.length) {
        this.items = [];
        return;
      }

      var url = new URL(this.$backend.RESTAURANT.GET_ALL_LOCATIONS),
        params = {
          country: this.country,
          zips: this.zips.map(z => z.zcZip).join(),
          published: this.showOnlyPublished === true ? true : null
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.loading = true;
      const { data } = await this.$axios.get(url);

      // Add css classes to items
      Object.keys(data).map(function(key, index) {
        let item = data[index];
        item._classes = item.none_active == 1 ? 'strikeout' : '';
      });

      this.items = data.map(({ zip_codes, ...el }) => {
        return {
          ...el,
          zips: zip_codes.reduce((group, zip) => {
            const { zcCountryId } = zip;
            group[zcCountryId] = group[zcCountryId] ?? [];
            group[zcCountryId].push({ zip: zip.zcZip, city: zip.zcCity });
            return group;
          }, {})
        }
      });

      this.loading = false;
    }
  }
};
</script>

<style>
.zone-table table {
  margin-bottom: 0;
}
.zone-table table th, .zone-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}

.zone-list {
	list-style: none;
	margin: 0;
	padding-left: 0;
}
.zone-list > li {
	margin-bottom: 1em;
}
.zone-list > li:last-child {
	margin-bottom: 0;
}

.zone-list-inline {
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	display: inline;
}
.zone-list-inline > li {
	display: inline-block;
	margin-left: 0;
	margin-right: 1em;
}
</style>
